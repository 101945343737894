import { DOCUMENT } from '@angular/common';
import {
  Inject,
  Injectable,
} from '@angular/core';

import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '@auth0/auth0-angular';
import {
  BehaviorSubject,
  Observable,
  of,
  throwError,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
} from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ClaimsModel } from '../models/claimsModel';
import { ErrorModel } from '../models/error.model';
import { LoggedUserModel } from '../models/logged-user.model';

import { TelemetryService } from './telemetry.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedUserService {
  private loggedUser$$ = new BehaviorSubject<LoggedUserModel>({
    pictureUri: undefined,
    name: '',
    initials: 'NA',
    id: '',
    tenantId: '',
    participantIds: [],
  });

  public loggedUser$ = this.loggedUser$$.asObservable();

  private _accessToken?: string;

  constructor(
    private jwtHelperService: JwtHelperService,
    private authService: AuthService,
    private telemetryService: TelemetryService,
    @Inject(DOCUMENT) private doc: Document,
  ) {
    this.authService.user$.pipe(distinctUntilChanged()).subscribe((user) => {
      const loggedUser = this.loggedUser$$.value;
      loggedUser.pictureUri = user?.picture;

      if (user?.name) {
        loggedUser.name = user.name;
        const parts = user.name.split(' ');
        loggedUser.initials = (parts[0][0] + (parts[1] ? parts[1][0] : '')).toUpperCase();
      } else {
        loggedUser.name = '';
        loggedUser.initials = 'NA';
      }

      this.telemetryService.tenantId = loggedUser.tenantId;
      this.loggedUser$$.next(loggedUser);
      this.getAccessTokenSilently();
    });
  }

  public get loggedUser(): LoggedUserModel {
    return this.loggedUser$$.value;
  }

  public get isUserAuthenticated$(): Observable<boolean> {
    return this.authService.isAuthenticated$;
  }

  public get error$(): Observable<ErrorModel> {
    return this.authService.error$.pipe(
      map((x: Error | { error_description: string }) => <ErrorModel>{
        message: 'error_description' in x ? x.error_description : x.message,
      }),
    );
  }

  public get accessToken(): string | undefined {
    return this._accessToken;
  }

  public get claims(): ClaimsModel {
    if (!this._accessToken) {
      return {};
    }

    return this.jwtHelperService.decodeToken<ClaimsModel>(this._accessToken) ?? {};
  }

  public get permissions(): string[] {
    return this.claims['permissions'] as string[];
  }

  public logout(): void {
    this.authService.logout({ logoutParams: { returnTo: this.doc.location.origin } });
  }

  private getAccessTokenSilently(): void {
    this.authService.getAccessTokenSilently().pipe(
      catchError((error: Error) => {
        if (error.message.toLowerCase().includes('missing refresh token')) {
          return of('');
        }
        return throwError(() => error);
      }),
    ).subscribe((token) => {
      this._accessToken = token;

      const claims = this.jwtHelperService.decodeToken<ClaimsModel>(
        this._accessToken,
      );
      if (claims) {
        const powerdeskClaims = claims[environment.auth0Namespace] as {
          tenantId: string;
          participantIds: string[];
          userId: string;
        } | undefined;
        const loggedUser = this.loggedUser$$.value;

        loggedUser.tenantId = powerdeskClaims?.tenantId || '';
        loggedUser.participantIds = powerdeskClaims?.participantIds || [];
        loggedUser.id = powerdeskClaims?.userId || '';

        this.telemetryService.tenantId = loggedUser.tenantId;
        this.loggedUser$$.next(loggedUser);
      }
    });
  }
}
